html {
	width: 100%;
	height: 100%;
	overflow-x: hidden !important;
}

body {
	font-family: $primaryFontFamily;
	color: $primaryTextColor;
	width: 100%;
	height: 100%;
	overflow-x: hidden !important;
}

a {
	&:hover {
		text-decoration: none;
	}
}

.form-group {
	.inner-addon {
		position: relative;

		.fa {
			position: absolute;
			padding: 10px;
			pointer-events: none;
			font-size: 14px;
		}

		&.left-addon {
			.fa {
				left: 0px;
			}
			input {
				padding-left: 30px;
			}
		}
		&.right-addon {
			.fa {
				right: 0px;
			}
			input {
				padding-right: 30px;
			}
		}
	}
}


.form-control {
	display: block;
	width: 100%;
	padding: 15px 20px;
	font-size: 14px;
	line-height: 1.42857143;
	color: #616161;
	background-color: #ffffff;
	border: none;
	border-radius: 0!important;
	transition: border-color ease-in-out .15s;
	height: auto;
	font-weight: 300;

	&:focus {
		outline: 0;
		border-color: $secondaryTextColor;
		box-shadow: none
	}

	&:active {
		border-color: $secondaryTextColor;
		box-shadow: none
	}

	&::-moz-placeholder {
		color: #7e7e7e;
		opacity: 1
	}
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
	cursor: not-allowed;
	background-color: #eee;
	opacity: 1
}

label {
	display: inline-block;
	font-size: 13px;
	letter-spacing: 1px;
	color: #555;
	margin-bottom: 10px;
	cursor: pointer;

	&.label-muted {
		color: #999;
		font-weight: 400;
		margin-right: 5px
	}
}

.btn {

	border-radius: 0;

	&.btn-primary {
		background-color: $secondaryTextColor;
		border-color: darken($secondaryTextColor, 6%);

		&:hover {
			background-color: darken($secondaryTextColor, 6%);
		}
	}

	&.btn-labeled {
		position: relative;
		.fa {
			position: absolute;
			height: 100%;
			line-height: 1;
			border-radius: 0;
			border-top-left-radius: inherit;
			border-bottom-left-radius: inherit;
			text-align: center;
			margin: 0;
			width: 2.57142857em;
			background-color: rgba(0,0,0,.2);
			box-shadow: -1px 0 0 0 transparent inset;
			top: 0;
			left: 0;
			opacity: .9;
			margin: 0!important;
			vertical-align: top;
			transition: opacity .1s ease;

			&:before {
				display: block;
				position: absolute;
				width: 100%;
				top: 50%;
				text-align: center;
				-webkit-transform: translateY(-50%);
				transform: translateY(-50%);
			}
		}
	}
}