@media screen and (max-width:767px) {
	#header {
		.navbar {
			&.navbar-default {
				position: absolute;
				width: 100%;

				.navbar-toggle {
					border : none;
					margin-right: 20px;

					.icon-bar {
						background-color: #ffffff;
					}

					&:hover {
						background-color: transparent;
					}
				}

				.navbar-collapse {
					margin-right: 30px;
					background: #111111;
					border : none;

					.navbar-nav {
						ul {
							li {
								margin-right:0;
								border-bottom: 1px solid #000;
								a {


								}

								&:last-child {
									border: none;
								}
							}
						}
					}
				}
			}
		}
	}

	#footer {
		.logo {
			margin-top: 90px!important;
		}
		.menu  {
			ul {
				display: flex;
				flex-direction:column;

				li {
					margin-bottom: 10px !important;

					&:before {
						content: none !important;
					}
				}
			}
		}
	}

	#main {
		.lead {
			background-position-x: -426px;

			.lead-form {
				width: 100% !important;
			}
		}

		.about {
			padding: 50px 0 !important;
			ul {
				li {
					.description {
						width: 100% !important;
					}
				}
			}
			.image {
				display: none;
			}
		}
	}

	.page {
		.page-header {
			.information {
				.description {
					font-size: 13px;
				}
			}
		}
	}
}