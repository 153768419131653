.page {
	.page-header {
		padding: 0;
		border: none;
		margin : 0;
		color: #ffffff;
		background-image: url("../img/header.png");
		height: 466px;
		margin-top: -70px;
		display : flex;
		align-items:center;
		width: 100%;

		.information {
			.title {
				font-weigh:500;
				font-size: 42px;
			}

			.description {
				font-size: 16px;
				margin-top: 10px;
			}
		}
	}

	.page-content {
		padding:100px 0;

		.image {
			margin-bottom : 20px;
		}
	}
}