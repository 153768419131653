@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600&subset=latin-ext');

$primaryFontFamily: 'Poppins', 'Helvetica Neue', Arial, Helvetica, sans-serif;
$secondaryFontFamily: 'Poppins', 'Helvetica Neue', Arial, Helvetica, sans-serif;
$primaryTextColor: #616161;
$primaryHoverColor: #a620c3;
$secondaryTextColor: #a620c3;
$secondaryHoverColor: #111;

@import "Reset";
@import "Widgets";

body {
	background: #fcf3fe;
}

#ribrit {
	#header {
		padding-top: 20px;
		position: relative;
		z-index: 999;

		.navbar {
			&.navbar-default {
				background: none;
				border: none;
				margin: 0;
				padding: 0;

				.navbar-nav {
					li {
						margin-right: 20px;
						a {
							font-size : 16px;
							color: #ffffff;

							&:hover {
								background: rgba(17, 17, 17, 0.4);
							}
						}

						&.active {
							a {
								background: rgba(17, 17, 17, 0.4);

								&:hover {
									background: rgba(17, 17, 17, 0.6);
								}
							}
						}

						&:last-child {
							margin-right: 0;
						}
					}
				}
			}
		}
	}

	#footer {
		background-image: url("../img/footer.png");
		background-size: cover;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 400px;

		.logo {
			margin: 40px 0;
		}

		.social-icons {
			display: flex;
			align-items: center;
			justify-content: center;
			a {
				margin-right: 40px;
				width: 50px;
				height: 50px;
				display: flex;
				align-items: center;
				justify-content: center;
				background: rgba(255, 255, 255, 0.3);
				font-size: 24px;
				color: #ffffff;
				border-radius: 50%;

				&:hover {
					background: rgba(17, 17, 17, 0.4);
				}

				&:last-child {
					margin-right: 0;
				}
			}
		}

		.menu {
			margin-top: 40px;
			ul {
				li {
					position: relative;
					padding: 0 10px;
					a {
						color: #ffffff;
						padding: 10px 15px;
						font-size: 16px;

						&:hover {
							background: rgba(17, 17, 17, 0.4);
						}
					}

					&.active {
						a {
							background: rgba(17, 17, 17, 0.4);

							&:hover {
								background: rgba(17, 17, 17, 0.6);
							}
						}
					}

					&:before {
						content: "-";
						position: absolute;
						color: #fff;
						top: 2px;
						left: -4px;
						font-size: 16px;
					}

					&:first-child {
						&:before {
							content: none;
						}
					}
				}
			}
		}
	}

	#main {
		.lead {
			margin-top: -70px;
			background-image: url("../img/lead.png");
			background-size: cover;
			height: 773px;
			width: 100%;
			color: #ffffff;

			.title {
				font-size: 42px;
				font-weight: 600;
				letter-spacing: -1px;
				margin-top: 140px;
			}

			.description {
				font-size: 16px;
			}

			.lead-form {
				padding: 20px 40px 40px 40px;
				background: rgba(17, 17, 17, 0.3);
				margin-top: 40px;
				width: 80%;

				.form-title {
					font-size: 20px;
					margin-bottom: 20px;
				}

				.form-group {
					.inner-addon {
						.fa {
							top: 5px;
							right: 10px;
							color: $secondaryTextColor;
							font-size: 20px;
						}
					}

					.btn-labeled {
						font-size: 20px;
						font-weight: 400;
					}
				}
			}
		}

		.about {
			padding: 150px 0;
			position: relative;

			ul {
				list-style: none;
				margin: 0;
				padding: 0;
				position: relative;

				&:before {
					content: "";
					background: #BABABA;
					position: absolute;
					width: 2px;
					height: 94%;
					left:-30px;
					top: 25px;
				}

				li {
					margin-bottom: 40px;
					position: relative;
					.title {
						font-size: 32px;
						font-weight: 500;
					}
					.description {
						font-size: 16px;
						width: 630px;
					}

					&:before {
						content: "";
						background: #851C9C;
						position: absolute;
						width: 10px;
						height: 10px;
						left: -34px;
						border-radius: 50%;
						top: 20px;
					}
				}
			}

		}
	}
}

@import "Pages";
@import "Responsive";